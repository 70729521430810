<template>
  <div class="MonitorNodesItem row align-items-center">
    <div class="col col-location">
      <div class="col-label">Location</div>
      <node-name :node="node"
                 :inline="true" />
    </div>

    <template v-if="monitorNode">
      <div class="col col-uptime">
        <div class="col-label">Uptime</div>
        <node-uptime :uptime="monitorNode.uptime" />
      </div>
      <div class="col col-response-time">
        <div class="col-label">Response Time</div>
        <node-response-time :response-time="monitorNode.average_response_time" />
      </div>
      <div class="col col-status">
        <div class="col-label">Status</div>
        <template v-if="monitor.is_running === false">
          <div class="col col-no-data">
            <div class="badge">Monitor Paused</div>
          </div>
        </template>
        <div v-else class="status"
             :class="nodeClasses">
          <template v-if="monitorNode.active === false">
              Disabled
          </template>
          <template v-else-if="monitorNode.latest_check">
            <template v-if="monitorNode.status === 'online'">
              <router-link :to="getCheckRoute(monitorNode.latest_check)">
                Checked at {{ monitorNode.checked_at | shortDate }}
              </router-link>
            </template>
            <template v-if="monitorNode.status === 'offline'">
              <router-link :to="getCheckRoute(monitorNode.latest_check)">
                Failed at {{ monitorNode.checked_at | shortDate }}
              </router-link>
            </template>
            <template v-if="!monitorNode.status">
              Checking...
            </template>
          </template>
          <template v-else>
            Checking....
          </template>
        </div>
      </div>
    </template>
    <template v-else-if="monitor.nodes.length < user.subscription_plan.region_checks">
      <div class="col col-no-data">
        <div class="badge" style="background-color: #28a745">You can enable this node.</div>
      </div>
    </template>
    <template v-else>
      <div class="col col-no-data">
        <div class="badge">Upgrade your plan to access this location.</div>
      </div>
    </template>
    <div class="col col-actions" v-if="user.role !== 'viewer' && user.role !== 'accountant'">
      <div class="col-label">Enabled</div>
      <div v-if="isNodeActive(node) && isNodeAvailable && monitor.nodes.length === 3">
        <base-toggle :value="true"
                     :disabled="true"
                     checked-text="Yes"
                     unchecked-text="No"
        />
      </div>
      <div v-else-if="isNodeAvailable">
        <base-toggle :value="isNodeActive(node)"
                        checked-text="Yes"
                        unchecked-text="No"
                        @input="onNodeToggle($event, node)" />
      </div>
      <div v-else>
        <base-toggle :value="false"
                     :disabled="true"
                     checked-text="Yes"
                     unchecked-text="No"
                     />
      </div>
    </div>
  </div>
</template>

<script>
import NodeName from '@/components/Node/NodeName.vue'
import NodeUptime from '@/components/Common/Uptime.vue'
import NodeResponseTime from '@/components/Common/ResponseTime.vue'

export default {
  components: {
    NodeName,
    NodeUptime,
    NodeResponseTime
  },

  props: {
    monitor: {
      required: true,
      type: Object
    },
    node: {
      required: true,
      type: Object
    },
    user: {
      required: true,
      type: Object
    }
  },

  methods: {
    getCheckRoute (check) {
      return {
        name: 'monitors.single.checks.single',
        params: {
          id: this.monitor.id,
          checkId: check
        }
      }
    },

    isNodeActive (node) {
      let node_ids = []
      JSON.parse(JSON.stringify(this.monitor.nodes)).forEach(getIds)
      function getIds (value) {
        node_ids.push(value.id)
      }
      return node_ids.includes(node.id)
    },

    onNodeToggle (isActive, node) {
      this.$emit('toggle', {
        isActive,
        node
      })
    }
  },

  computed: {
    monitorNode () {
      return this.monitor.nodes.find(mNode => mNode.id === this.node.id)
    },

    isNodeAvailable () {
      if (this.monitor.nodes.find(monitorNode => monitorNode.id === this.node.id) || this.monitor.nodes.length < this.user.subscription_plan.region_checks) {
        return true
      } else {
        return false
      }
    },

    nodeClasses () {
      let classes = []
      if (this.monitorNode.active === false) {
        classes.push('disabled')
      } else if (this.monitorNode.status) {
        classes.push(this.monitorNode.status)
        classes.push('badge-hoverable')
      } else {
        classes.push('checking')
      }

      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorNodesItem {
    @include monitor-nodes-columns;

    @media (max-width: 1264px) {
      box-shadow: 0 2px 4px rgba(25,34,29,0.05);
      border-radius: 6px;
      background-color: #fff;
      padding: 15px 15px 10px;
    }

    .col-label {
      display: none;
      color: #999ca7;
      font-weight: 600;
      font-size: 12px;
      margin-bottom: 3px;

      @media (max-width: 1264px) {
        display: block;
      }
    }

    .col {
      @media (max-width: 1264px) {
        margin-bottom: 10px;
      }
    }

    .col-status {
      @media (max-width: 1264px) {
        flex: 1;
      }
    }

    .col-actions {
      @media (max-width: 1130px) {
        flex: 0 0 140px;
      }
    }

    .col-uptime, .col-response-time {
      @media (max-width: 480px) {
        flex: 1;
        width: 100%;
        min-width: 150px;
      }
    }

    .col-location {
      @media (max-width: 480px) {
        flex: 1;
        width: 100%;
      }
    }

    .col-actions {
      @media (max-width: 480px) {
        flex: 1;
        width: 100%;
        min-width: 200px;
      }
    }

    .status {
      @include badge;
      max-width: 250px;

      @media (max-width: 1264px) {
        max-width: 100%;
        min-width: 150px;
      }

      @media (max-width: 1130px) {
        min-width: 280px;
        max-width: 280px;
      }

      @media (max-width: 767px) {
        max-width: 100%;
        min-width: 150px;
      }

      &.online {
        @include badge-success;
      }

      &.offline {
        @include badge-danger;
      }

      &.checking {
        @include badge-warning;
      }

      &.disabled {
      @include badge-disabled;
    }
    }
  }
</style>
